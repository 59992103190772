<template>
  <router-view/>
</template>

<style>
  body {
    font-size: 15px;
    font-weight: normal;
    color: #718096;
  }

  
</style>
